<template>
    <div class="check_text" @click="checkLogin">{{ $t('m.buygiftPacktips') }}</div>
</template>

<script>
export default({
    data() {
        return {

        }
    },
    methods: {
        checkLogin(){
            //console.log('验证登录-----')
            this.$emit('checkLogin',1, 1)
        }
    }
})
</script>

<style lang="scss" scoped>
.check_text {
    width: 343px;
    font-size: 12px;
    font-weight: 400;
    color: #FF4D4F;
    line-height: 14px;
    margin: 10px auto 14px auto;
}
</style>